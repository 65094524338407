import React, { useState } from 'react';
import { Affix, Button, Col, DatePicker, Row, Tag } from 'antd';
import DisplayEmailData from '../common/DisplayEmailData.js';
import { useAppContext } from '../../contexts/AppContext.js';
import dayjs from "../../utils/dayjsConfig.js"
import URLSelector from './URLSelector.js';
import { CalendarOutlined, ClockCircleOutlined, DashboardOutlined, SettingOutlined } from '@ant-design/icons';
import SettingsDrawer from './SettingsDrawer.js';
const EmailDatePicker = () => {
  const { monitorEmails, selectedEmail, setSelectedEmail, fetchDataForEmail, selectedDate, setSelectedDate, currentVisibleDate, ref1, ref2, settingsRef } = useAppContext();
  const [affixStyle, setAffixStyle] = useState({})
  const [isAffixed, setIsAffixed] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)



  const handleEmailChange = (email) => {
    setSelectedEmail(email);
    const [start, end] = selectedDate;
    if (start && end) {
      fetchDataForEmail(email, start, end);
    }
  };

  const handleDateChange = (dates) => {
    const start = dayjs.tz(`${dates[0].$y}-${dates[0].$M + 1}-${dates[0].$D} 00:00:00`, 'America/Los_Angeles').format()
    const end = dayjs.tz(`${dates[1].$y}-${dates[1].$M + 1}-${dates[1].$D} 23:59:59`, 'America/Los_Angeles').format()

    setSelectedDate([start, end])

    if (selectedEmail) {
      fetchDataForEmail(selectedEmail, start, end)
    }
  };

  const onAffixChange = (affixed) => {
    setIsAffixed(!isAffixed)
    if (affixed) {
      setAffixStyle({
        backdropFilter: 'blur(10px)', // Apply blur when affixed
        transition: 'backdrop-filter 2s ease, box-shadow 2s ease', // Smooth transitions
        padding: "15px 0"
      })
    } else {
      setAffixStyle({})
    }
  }




  return (
    <div>
      <SettingsDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}></SettingsDrawer>
      <Affix offsetTop={0} onChange={onAffixChange}>
        <div style={{ marginTop: 15, position: 'absolute', zIndex: 100, display: isAffixed ? 'block' : 'none' }}>
          {
            currentVisibleDate
            ?
            <>
            <Tag style={{ color: '#666666', fontSize: 14, padding: '5px 10px' }}><CalendarOutlined style={{ color: '#1777ff' }} /><span style={{ marginLeft: 10 }}>{currentVisibleDate.day}</span></Tag>
            <Tag style={{ color: '#666666', fontSize: 14, padding: '5px 10px' }}><ClockCircleOutlined style={{ color: '#1777ff' }} /><span>{currentVisibleDate.totalLogged}</span></Tag>
            <Tag style={{ color: '#666666', fontSize: 14, padding: '5px 10px' }}><DashboardOutlined style={{ color: '#1777ff' }} /><span>{currentVisibleDate.overallActivity}%</span></Tag>
            </>
            :
            null
          }
        </div>
        <div className='affixTransition' style={affixStyle}>
          <Row style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <div ref={ref1}>
              <DisplayEmailData emails={monitorEmails} selectedEmail={selectedEmail} onEmailChange={handleEmailChange} />
            </div>
            <div ref={ref2}>
              <DatePicker.RangePicker
                className="date-picker"
                format="YYYY-MM-DD"
                onChange={handleDateChange}
                superNextIcon={null}
                superPrevIcon={null}
                presets={[
                  {
                    label: 'Today',
                    value: [dayjs(), dayjs()],
                  },
                  {
                    label: 'Yesterday',
                    value: [dayjs().subtract(1, 'd'), dayjs().subtract(1, 'd')],
                  },
                  {
                    label: 'Last 7 Days',
                    value: [dayjs().add(-7, 'd'), dayjs()],
                  },
                  {
                    label: 'Last Week',
                    value: [dayjs().subtract(1, 'week').startOf('isoWeek'), dayjs().subtract(1, 'week').endOf('isoWeek')],
                  },
                  {
                    label: 'This Week',
                    value: [dayjs().startOf('isoWeek'), dayjs().endOf('isoWeek')],
                  },
                  {
                    label: 'Last Month',
                    value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
                  },
                  {
                    label: 'This Month',
                    value: [dayjs().startOf('month'), dayjs().endOf('month')],
                  }
                ]}
              />
            </div>
            <div ref={settingsRef} style={{ marginLeft: 10 }}>
              <Button
                type="primary"
                icon={<SettingOutlined />}
                onClick={() => setDrawerOpen(!drawerOpen)}
              />
            </div>
          </Row>
          <Row style={{
            display: 'flex',
            justifyContent: 'center',
            margin: "10px 0"
          }}>
            <Col span={10}>
              <URLSelector></URLSelector>
            </Col>
          </Row>
        </div>
      </Affix>
    </div>
  );
};

export default EmailDatePicker;
