import React, { useState } from 'react';
import { Button, Image, Tour } from 'antd';
import { useAppContext } from '../../contexts/AppContext.js';
import { signOut } from 'firebase/auth';
import { auth } from '../../utils/firebase.js';

const WelcomeContainer = () => {
  const { loggedUser, userRole, steps } = useAppContext();

  const [tourOpen, setTourOpen] = useState(false);

  return (
    <div className="welcome-container">
      <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={steps} />
      <div className="user-info">
        <Image width={40} src={loggedUser.photoURL} />
        <div>
          <p>Welcome, <b>{loggedUser.displayName}</b> <Button onClick={() => setTourOpen(true)} type='text'>New here? Begin a tour!</Button></p>
          <p>Your role: <b>{userRole}</b></p>
        </div>
      </div>
      <Button onClick={() => signOut(auth)} type='text' danger>Sign Out</Button>
    </div>
  );
};

export default WelcomeContainer;
