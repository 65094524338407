import React, { useEffect, useCallback } from 'react';
import { Layout } from 'antd';
import WelcomeContainer from './components/layout/WelcomeContainer.js';
import EmailDatePicker from './components/layout/EmailDatePicker.js';
import StatsContainer from './components/layout/StatsContainer.js';
import TimeWindowGrid from './components/dashboard/TimeWindowGrid.js';
import MainContainer from './components/layout/MainContainer.js';
import SignIn from './components/auth/SignIn.js';
import axios from './utils/axiosConfig.js';
import { useAppContext } from './contexts/AppContext.js';
import { auth, getToken } from './utils/firebase.js';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import './App.css';

const { Content } = Layout;

const App = () => {
  const { loggedUser, setLoggedUser, setUserRole } = useAppContext();

  const checkUserRole = useCallback(async (token) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/security/test/check-role`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUserRole(response.data.role);
    } catch (error) {
      signOut(auth);
    }
  }, [setUserRole]);

  useEffect(() => {
    const handleUserStateChange = async (user) => {
      if (user) {
        setLoggedUser(user);
        try {
          const token = await getToken(user);

          // Set token in local storage
          localStorage.setItem('token', token);

          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          await checkUserRole(token);
        } catch (error) {
          console.error("Error getting token:", error);
        }
      } else {
        setLoggedUser(null);
        delete axios.defaults.headers.common['Authorization'];
      }
    };

    const unsubscribe = onAuthStateChanged(auth, handleUserStateChange);
    return () => unsubscribe();
  }, [setLoggedUser, checkUserRole]);

  return (
    loggedUser ? (
      <Layout>
        <Content style={{ padding: '48px' }}>
          <WelcomeContainer />
          <EmailDatePicker />
          <StatsContainer />
          <MainContainer>
            <TimeWindowGrid />
          </MainContainer>
        </Content>
      </Layout>
    ) : (
      <SignIn />
    )
  );
};

export default App;
