import React from "react";
import { Col, Progress, Tag, Tooltip } from "antd";
import { useAppContext } from "../../contexts/AppContext.js";


const TaskTimeBar = (props) => {
    const { appActivityPercentage, color, totalTracked } = props;


    return (
        <Tooltip placement='bottom' title={`Time spent on this url: ${totalTracked/60<1 ? `${totalTracked} seconds` : `${Math.floor(totalTracked/60)} minutes and ${totalTracked%60} seconds`} (${appActivityPercentage}%)`}>
            <Progress showInfo={false} size="small" percent={appActivityPercentage} strokeColor={color}  />
        </Tooltip>
    )
}

const ApplicationsTags = ({cardAppDetails, overallTracked, timeSlot}) => {
    return (
        cardAppDetails.map(app => {
            let appActivityPercentage = Math.round(app.app_tracked_time / overallTracked * 100);
            return (
                <Col key={`${timeSlot}-${app.app_name}-${app.app_tracked_time}`} span={11} style={{textAlign: "center"}}>
                    <Tooltip placement='bottom' title={`${app.app_name}`}>
                        <Tag style={{ marginTop: 7, fontSize: 10}}><div>{app.app_name.slice(0,8)}</div></Tag>
                    </Tooltip>
                    <TaskTimeBar appActivityPercentage={appActivityPercentage} totalTracked={overallTracked}></TaskTimeBar>
                </Col>
            )
        })
    )
}

export default ApplicationsTags;