import React from "react";
import { Tooltip, Tag, Progress, Col } from "antd";
import { useAppContext } from "../../contexts/AppContext.js";
import PropTypes from 'prop-types';
import { getUrlHost, isTaskURL } from "../../utils/functions.js";



// Progress bar for visited urls
const TaskTimeBar = (props) => {
    const { urlActivityPercentage, color, totalTracked } = props;


    return (
        <Tooltip placement='bottom' title={`Time spent on this url: ${totalTracked/60<1 ? `${totalTracked} seconds` : `${Math.floor(totalTracked/60)} minutes and ${totalTracked%60} seconds`} (${urlActivityPercentage}%)`}>
            <Progress showInfo={false} size="small" percent={urlActivityPercentage} strokeColor={color}  />
        </Tooltip>
    )
}


TaskTimeBar.propTypes = {
    urlActivityPercentage: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    totalTracked: PropTypes.number.isRequired
  };


const VisitedTasksTags = (props) => {
    const { selectedTasks, ref9 } = useAppContext();
    const { cardVisitedURLs, overallTracked } = props;


    return (
        cardVisitedURLs.map(( urlActivity ) => {
            const isTask = isTaskURL(urlActivity.url)
            let {url, title, color} = selectedTasks.filter((obj) => isTask ? (urlActivity.url.includes(obj.url) && isTaskURL(obj.url)) : urlActivity.url.includes(obj.url))[0] || {url: null, title: null, color: null};
            
            let urlActivityPercentage = Math.round(urlActivity.tracked / overallTracked * 100);
            if(url){
                if(isTask){
                    return (
                        <Col key={`${urlActivity.url}`} ref={ref9} span={11} style={{textAlign: "center"}}>
                            <a target='blank' href={urlActivity.url}>
                                <Tooltip placement='bottom' title={`${url}`}>
                                    <Tag style={{ borderWidth: 1.5, marginTop: 7, backgroundColor: "rgba(0, 0, 0, 0.01)", fontSize: 10 }} color={color}><div> {urlActivity.id.slice(0,8)}</div></Tag>
                                </Tooltip>
                            </a>
                            <TaskTimeBar urlActivityPercentage={urlActivityPercentage} totalTracked={urlActivity.tracked} color={color}></TaskTimeBar>
                        </Col>
                    )
                }else{
                    return (
                        <Col key={`${urlActivity.url}`} ref={ref9} span={11} style={{textAlign: "center"}}>
                            <a target='blank' href={urlActivity.url}>
                                <Tooltip placement='bottom' title={`[${getUrlHost(urlActivity.url)}] ${urlActivity.title}`}>
                                    <Tag style={{ marginTop: 7, fontSize: 10}} color={color}><div>{urlActivity.title.slice(0,8)}</div></Tag>
                                </Tooltip>
                            </a>
                            <TaskTimeBar urlActivityPercentage={urlActivityPercentage} totalTracked={urlActivity.tracked} color={color}></TaskTimeBar>
                        </Col>
                    )
                }
            }
        })

    )
}

VisitedTasksTags.propTypes = {
    cardVisitedURLs: PropTypes.arrayOf(PropTypes.object).isRequired,
    overallTracked: PropTypes.number.isRequired
  };

export default VisitedTasksTags;