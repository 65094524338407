import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, Switch } from "antd";
import { useAppContext } from "../../contexts/AppContext.js";

const SettingsDrawer = ({drawerOpen, setDrawerOpen}) => {
    const {detailedURLMode, setDetailedURLMode, onlySuspiciousMode, setOnlySuspiciousMode, setGeneralLoading} = useAppContext();
    const [detailedURLModeTemp, setDetailedURLModeTemp] = useState(detailedURLMode)
    const [onlySuspiciousModeTemp, setOnlySuspiciousModeTemp] = useState(onlySuspiciousMode)

    const onFinish = () => {
        setDetailedURLMode(detailedURLModeTemp)
        setOnlySuspiciousMode(onlySuspiciousModeTemp)
    }

    useEffect(() => {
        setDetailedURLModeTemp(detailedURLMode)
        setOnlySuspiciousModeTemp(onlySuspiciousMode)
    }, [drawerOpen])

    return(
        <Drawer title="Settings" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Form
        layout='vertical'
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        >
          <Form.Item label='Multiple URL tags per host'>
          <Switch checked={detailedURLModeTemp} onChange={() => setDetailedURLModeTemp(!detailedURLModeTemp)}></Switch>
          </Form.Item>
          <Form.Item label='Show only suspicious windows'>
          <Switch checked={onlySuspiciousModeTemp} onChange={() => setOnlySuspiciousModeTemp(!onlySuspiciousModeTemp)}></Switch>
          </Form.Item>
          <Form.Item>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
        </Form>
      </Drawer>
    )
}

export default SettingsDrawer;