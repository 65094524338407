import dayjs from './dayjsConfig.js';

export const getTaskId =(url) => {
    return url.match(/[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)+/)[0]
}

export const isTaskURL = (url) => {
  const taskIdPattern = /[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)+/;
  return url.includes("feather.openai.com/tasks") && taskIdPattern.test(url)
}

export const strTimeSpent = (tracked, showSeconds=false, min=false) => {
    let hours = Math.floor(tracked / 3600);
    let minutes = Math.floor((tracked % 3600) / 60);
    let seconds = tracked % 60;

    const hourStr = min ? "h" : " hour(s), "
    const secStr = min ? "s" : " second(s)"
  
    if(showSeconds){
        const minStr = min ? "m" : " minute(s), "

        return hours > 0 
        ? `${hours}${hourStr}${minutes}${minStr}${seconds}${secStr}` 
        : minutes > 0 
        ? `${minutes}${minStr}${seconds}${secStr}` 
        : `${seconds}${secStr}`;
    }else{
        const minStr = min ? "m" : " minute(s)"

        if(seconds>=30){
            minutes += 1
        }

        if (minutes === 60) {
          minutes = 0;
          hours += 1;
        }


        return hours > 0 
          ? `${hours}${hourStr}${minutes}${minStr}` 
          : minutes > 0 
          ? `${minutes}${minStr}` 
          : `${seconds}${secStr}`;
    }
  }

  export const getUrlHost = (url) => {
    return new URL(url).host
  }

  export const getUrlProtocol = (url) => {
    return new URL(url).protocol
  }

  export const getBaseURL = (url) => {
    return `https://${getUrlHost(url)}`
  }

  export const replaceProtocol = (url) => {
    try{
      const urlObj = new URL(url);
      return `https://${urlObj.host}${urlObj.pathname}${urlObj.search}`
    }catch(e){
      console.log(e, url)
      return `https://invalidurl.com?originalUrl=${url}`
    }
  }

  export const areEqualTimeSlots = (timeSlot1, timeSlot2) => {
    // Convert both time slots to UTC before comparison
    const time1 = dayjs(timeSlot1).utc();
    const time2 = dayjs(timeSlot2).utc();
  
    if (time1.isSame(time2)) return true

    return false
  }

  export const strDuration = (totalSec) => {
    const hours = Math.floor(totalSec / 3600);
    const minutes = Math.floor((totalSec % 3600) / 60);
    
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  }