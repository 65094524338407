import React from 'react';
import { Card, Col, Row, Statistic, Typography } from 'antd';
import { ClockCircleOutlined, DashOutlined, DashboardOutlined } from '@ant-design/icons';
import { useAppContext } from '../../contexts/AppContext.js';
import { getUrlHost, strTimeSpent, isTaskURL, strDuration } from '../../utils/functions.js';

const { Text, Link } = Typography;

const getTaskId =(url) => {
  return url.match(/[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)+/)[0]
}

const StatsContainer = () => {
  const { activities, refOverallStats, sortedUniqueUrls, appDetails } = useAppContext();

  const totalTracked = activities.reduce((accumulator, currentObject) => {
    return accumulator + currentObject.activity_tracked_time;
  }, 0);

  const totalIDLE = strDuration(activities.reduce((accumulator, currentObject) => {
    if(currentObject.has_suspicious && "idle_time" in currentObject.suspicious_findings) return accumulator + currentObject.activity_tracked_time
    return accumulator
  }, 0))

  const flagsOccurrences = activities.reduce((accumulator, currentObject) => {
    if(currentObject.has_suspicious){
      for(const key of Object.keys(currentObject.suspicious_findings)){
        if(key in accumulator){
          accumulator[key] += 1
        }else{
          accumulator[key] = 1
        }
      }
    }

    return accumulator
  }, {})

  const sortedTopFlags = Object.entries(flagsOccurrences)
  .sort(([, valueA], [, valueB]) => valueB - valueA)
  .reduce((accumulator, [key, value]) => {
    accumulator[key] = value;
    return accumulator;
  }, {});

  const overallActSeconds = activities.reduce((accumulator, currentObject) => {
    return accumulator + currentObject.overall_activity;
  }, 0);
  
  const totalLogged = strDuration(totalTracked)

  const avgActivity = Math.round((overallActSeconds / totalTracked) * 100);

  return (
    <Row justify="center" align="middle" gutter={[5, 24]} ref={refOverallStats}>
      <Col span={8}>
        <Row gutter={[5, 5]} justify={'end'}>
        <Col span={12}>
        <Card className="stats-card">
          <Statistic
            title="Total Logged"
            value={totalLogged}
            prefix={<ClockCircleOutlined />}
          />
        </Card>
        </Col>
        <Col span={12}>
        <Card className="stats-card">
          <Statistic
            title="AVG. Activity"
            value={isNaN(avgActivity) ? 0 : avgActivity}
            suffix="%"
            prefix={<DashboardOutlined />}
          />
        </Card>
        </Col>
        <Col span={12}>
        <Card className="stats-card">
          <Statistic
            title="IDLE Time"
            value={totalIDLE}
            prefix={<ClockCircleOutlined />}
          />
        </Card>
        </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Row gutter={[5, 5]}>
        <Col span={12}>
        <Card className="stats-card">
          <Text type="secondary">Top 5 URLs</Text>
          <br></br>
          {
            sortedUniqueUrls.length > 0
            ?
            sortedUniqueUrls.slice(0,5).map( urlObj => (
              <Row key={urlObj.url}>
              <Col>
                <b>{isTaskURL(urlObj.url) ? getTaskId(urlObj.url).slice(0, 8) : getUrlHost(urlObj.url)}</b>
              </Col>
              <Col>
              <span style={{marginLeft: 5}}>{strTimeSpent(urlObj.tracked, false)}</span>
              </Col>
            </Row>
            ))
            :
            <DashOutlined />
          }
        </Card>
        </Col>
        <Col span={12}>
        <Card className="stats-card">
          <Text type="secondary">Top 5 APPs</Text>
          <br></br>
          {
            appDetails.length > 0
            ?
            appDetails.slice(0,5).map( obj => (
              <Row key={obj.appName}>
              <Col>
                {
                  obj.appName.length>10
                  ?
                  <b>{`${obj.appName.slice(0,10)}...`}</b>
                  :
                  <b>{obj.appName}</b>
                }
              </Col>
              <Col>
              <span style={{marginLeft: 5}}>{strTimeSpent(obj.appTrackedtime, false)}</span>
              </Col>
            </Row>
            ))
            :
            <DashOutlined />
          }
        </Card>
        </Col>
        <Col span={12}>
        <Card className="stats-card">
          <Text type="secondary">Top flags (Nº of windows)</Text>
          <br></br>
          {
            Object.keys(sortedTopFlags).length > 0
            ?
            Object.keys(sortedTopFlags).map( key => (
              <Row key={key}>
              <Col>
                <b>{key}</b>
              </Col>
              <Col>
              <span style={{marginLeft: 5}}>{sortedTopFlags[key]}</span>
              </Col>
            </Row>
            ))
            :
            <DashOutlined />
          }
        </Card>
        </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default StatsContainer;
