import React from 'react';
import { Button } from 'antd';
import { signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../../utils/firebase.js';

const SignIn = () => {
  const handleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Button type="primary" onClick={handleSignIn}>Sign In with Google</Button>
    </div>
  );
};

export default SignIn;
