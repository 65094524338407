import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

const { Option } = Select;

const DisplayEmailData = ({ emails, selectedEmail, onEmailChange }) => (
  <Select
    showSearch
    value={selectedEmail}
    onChange={onEmailChange}
    style={{ width: 200 }}
    placeholder="Select an email"
    filterOption={(input, option) => 
      option.children.toLowerCase().includes(input.toLowerCase())
    }
  >
    {emails.map((email) => (
      <Option key={email} value={email}>
        {email}
      </Option>
    ))}
  </Select>
);

DisplayEmailData.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedEmail: PropTypes.string,
  onEmailChange: PropTypes.func.isRequired,
};

export default DisplayEmailData;
