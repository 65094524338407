import { ClockCircleOutlined, MenuOutlined } from '@ant-design/icons';
import { Select, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../contexts/AppContext.js';
import {getTaskId, isTaskURL} from '../../utils/functions.js'
import { strTimeSpent, getUrlHost } from '../../utils/functions.js';

const colors = [
  "blue",
  "red",
  "green",
  "yellow",
  "cyan",
  "magenta",
  "lime",
  "purple",
  "orange",
  "gold",
  "pink",
  "geekblue"
]

const solid_colors = [
  "#FF005C",
  "#BF00FF",
  "#FFDD00",
  "#39FF14",
  "#00A4FF",
  "#FF4500",
  "#FF6F61",
  "#FF00FF",
  "#32CD32",
  "#40E0D0",
  "#E066FF",
  "#DAA520",
  "#FF69B4",
  "#7FFFD4" 
]



const splitOptionValue = (value) => {
  return value.split("|split|")
}


const TagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const {refUrlSelectorTag, seeMoreTagRef} = useAppContext();

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const TagContent = (props) => {
    const {value, label} = props;

    if(value){
      const [url, title, tracked] = splitOptionValue(value);
      if(isTaskURL(url)){
        const taskId = getTaskId(url)
        return(
          <Tooltip title={<span>{`${taskId} `}<br/><ClockCircleOutlined />{` ${strTimeSpent(tracked, true)}`}</span>}>
            <a target='blank' style={{ color: "unset" }} href={url}>{taskId.slice(0,8)}
            </a>
          </Tooltip>
        )
      }else{
        return(
          <Tooltip title={<span>{`${url} `}<br /><ClockCircleOutlined />{` ${strTimeSpent(tracked, true)}`}</span>}>
            <a target='blank' style={{ color: "unset" }} href={url}>{getUrlHost(url)}
            </a>
          </Tooltip>
        )
      }
    }else{
      return(<div>{label}</div>)
    }
  }
  
  return (
    <Tag
      color={label}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginInlineEnd: 4,
        textWrap: 'balance'
      }}
    > 
      {
        <span ref={value ? refUrlSelectorTag : seeMoreTagRef}>
        <TagContent value={value} label={label}></TagContent>
        </span>
      }
    </Tag>
  );
};

const URLSelector = () => {
  const { setSelectedTasks, ref8, selectAllButRef, sortedUniqueUrls } = useAppContext();

  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedOptions([]);

    const optionsObjs = sortedUniqueUrls.map(({url, title, tracked}, index) => {
      return ({
        value: [url, title, tracked].join("|split|"),
        label: isTaskURL(url) ? colors[index % colors.length] : solid_colors[index % solid_colors.length]
      })
    }
    )

    setOptions(optionsObjs)
  }, [sortedUniqueUrls])

  const handleSelectChange = (values) => {
    setSelectedOptions(values);
    let selectedOptions = options.filter(option => values.includes(option.value))
    selectedOptions = selectedOptions.map(obj => ({
      color: obj.label,
      url: splitOptionValue(obj.value)[0],
      title: splitOptionValue(obj.value)[1],
      tracked: splitOptionValue(obj.value)[2]
    }))
    setSelectedTasks(selectedOptions);
  }

  return (
    <div ref={ref8}>
        <Select
          mode="multiple"
          tagRender={TagRender}
          style={{
            width: '100%'
          }}
          options={options}
          placeholder="URLs"
          onChange={handleSelectChange}
          optionRender={(option) => <span><ClockCircleOutlined style={{marginRight: 3}} />{`${strTimeSpent(splitOptionValue(option.data.value)[2], false, true)} ${splitOptionValue(option.data.value)[0]}`}</span>}
          value={selectedOptions}
          maxTagCount={20}
          maxTagPlaceholder={(omittedValues) => (
            <Tooltip
              overlayStyle={{
                pointerEvents: 'none',
              }}
              title={omittedValues.map(({ value }) => {
                const [url] = splitOptionValue(value);
                if(isTaskURL(url)){
                  const taskId = getTaskId(url)
                  return "["+taskId.slice(0,8)+"]"
                }else{
                  return "["+getUrlHost(url)+"]"
                }
                
              }).join(' ')}
            >
              <span style={{color: "#666666"}}>See More</span>
            </Tooltip>
          )}
          suffixIcon={<div ref={selectAllButRef}><MenuOutlined onClick={() => handleSelectChange(options.map(option => option.value))}></MenuOutlined></div>}
          allowClear
          showSearch
        />
    </div>
  );
}


export default URLSelector;