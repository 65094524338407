import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Divider } from 'antd';
import { useAppContext } from '../../contexts/AppContext.js';
import dayjs from '../../utils/dayjsConfig.js';
import '../../App.css';
import ActivityCard from './ActivityCard.js';
import { ClockCircleOutlined, DashboardOutlined } from '@ant-design/icons';
import { MdOutlineMoreHoriz } from 'react-icons/md';
import SkeletonTimeWindow from './SkeletonTimeWindow.js';
import './CustomScrollbar.css'; 
import { getBaseURL, getUrlHost, isTaskURL } from '../../utils/functions.js';

const TimeWindowGrid = () => {
  const { activities, loading, detailedURLMode, onlySuspiciousMode, setCurrentVisibleDate, ref3, ref4 } = useAppContext();

  // Group activities by day
  const activitiesByDay = activities.reduce((acc, activity) => {
    const day = dayjs(activity.time_slot).tz(process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD');
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(activity);
    return acc;
  }, {});
  const sectionRefs = useRef([]);

  const getCardVisitedURLs = (activity) => {
    const cardVisitedURLs = activity.url_details || [];
    const groupedData = cardVisitedURLs.reduce((acc, item) => {
      const isTask = isTaskURL(item.url);
      if(isTask){
        // Extract the task ID from the URL
        const taskId = item.url.split('/tasks/')[1].split('/')[0];
      
        // If the taskId already exists in the accumulator, update the tracked time
        if (acc[taskId]) {
          acc[taskId].tracked += item.tracked; // Sum the tracked time
        } else {
          // Otherwise, create a new entry with the current item
          acc[taskId] = {
            id: taskId,
            url: item.url,
            title: item.title, // Take the first title
            tracked: item.tracked
          };
        }
      }else{
        if(detailedURLMode){
          acc[item.url] = item
        }else{
          const urlHost = getUrlHost(item.url);
          if (acc[urlHost]) {
            acc[urlHost].tracked += item.tracked; // Sum the tracked time
          } else {
            // Otherwise, create a new entry with the current item
            acc[urlHost] = {
              url: getBaseURL(item.url),
              title: getUrlHost(item.url), // Take the first title
              tracked: item.tracked
            }
          }
        }
      }
        return acc;
    }, {});

    const result = Object.values(groupedData);

    const sortedResult = result.sort((a, b) => b.tracked - a.tracked);

    return sortedResult
  }

  // Throttle function to limit the rate of function execution
  const throttle = (callback, limit) => {
    let lastFunc;
    let lastRan;

    return function () {
      const context = this;
      const args = arguments;

      if (!lastRan) {
        callback.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(() => {
          if ((Date.now() - lastRan) >= limit) {
            callback.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  };

  const handleScroll = throttle(() => {
    const scrollPosition = window.scrollY + 100; // Offset for sticky title
    for (let index = 0; index < sectionRefs.current.length; index++) {
    const ref = sectionRefs.current[index];
    if (ref && ref.element) {
      const { offsetTop, clientHeight } = ref.element;
      if (scrollPosition >= offsetTop && scrollPosition < offsetTop + clientHeight) {
        setCurrentVisibleDate(ref.dayInfo)
        break; 
      }
    }
  }
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activities]);

  return (
    <div>
      {loading ? (
        <SkeletonTimeWindow />
      ) : (
        <>
        {Object.keys(activitiesByDay).map((day) => {
          const totalTracked = activitiesByDay[day].reduce((acc, currentObject) => acc + currentObject.activity_tracked_time, 0);

          const overallActSeconds = activitiesByDay[day].reduce((acc, currentObject) => acc + currentObject.overall_activity, 0);

          const hours = Math.floor(totalTracked / 3600);
          const minutes = Math.floor((totalTracked % 3600) / 60);
          const totalLogged = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

          const overallActPer = Math.round((overallActSeconds / totalTracked) * 100);

          return (
            <div id={day} key={day} ref={(el) => {if(el) {sectionRefs.current.push({element: el, dayInfo: {day: day, totalLogged: totalLogged, overallActivity: overallActPer}})}}}>
              <Divider orientation="left" plain>
                <h3 ref={ref3}>{dayjs(day).format('MMMM D, YYYY')} <MdOutlineMoreHoriz style={{ marginLeft: 20 }} /> <ClockCircleOutlined style={{ marginLeft: 20 }} /> {totalLogged} <MdOutlineMoreHoriz style={{ marginLeft: 20 }} /> <DashboardOutlined style={{ marginLeft: 20 }} /> {overallActPer}%</h3>
              </Divider>
              <Row gutter={[16, 16]} justify="center" align="top" style={{ alignItems: "stretch" }}>
                {activitiesByDay[day].map((activity) => {
                  const cardVisitedURLs = getCardVisitedURLs(activity);

                  return (
                    ((onlySuspiciousMode && activity.has_suspicious) || !onlySuspiciousMode)
                    ?
                    <Col key={activity.time_slot} span={4}>
                      <div ref={ref4}>
                        <ActivityCard activity={activity} cardVisitedURLs={cardVisitedURLs}></ActivityCard>
                      </div>
                    </Col>
                    :
                    null
                  );
                })}
              </Row>
            </div>
          );
        })}
        </>
      )}
    </div>
  );
};

export default TimeWindowGrid;
