import React from 'react';
import { Card, Row, Col, Tooltip, Progress, Divider, Badge, Tag } from 'antd';
import { MdMouse, MdKeyboardAlt, MdPlayArrow, MdStop } from "react-icons/md";
import PropTypes from 'prop-types';
import VisitedTasksTags from './VisitedTasksTags.js';
import dayjs from "../../utils/dayjsConfig.js"
import { FaApple, FaLinux, FaWindows } from "react-icons/fa";
import { useAppContext } from '../../contexts/AppContext.js';
import ApplicationsTags from './ApplicationsTags.js';
import { isTaskURL } from '../../utils/functions.js';
import { areEqualTimeSlots } from '../../utils/functions.js';



const ActivityCard = ({ activity, cardVisitedURLs }) => {
  const { keyboard_percentage, mouse_percentage, overall_percentage, activity_tracked_time, starts_at, has_start, has_stop, client, project_name } = activity;
  const {selectedTasks, ref5, ref6, ref7, flagRef} = useAppContext();

  let startTime = dayjs(starts_at).tz(process.env.REACT_APP_TIMEZONE);
  let endTime = startTime.add(activity_tracked_time, "second");

  let clientDisplay = null;
  if(client == "windows"){
    clientDisplay = <FaWindows color='#666666' />;
  }else if(client == "osx"){
    clientDisplay = <FaApple color='#666666'/>
  }else if(client == "linux"){
    clientDisplay = <FaLinux color='#666666'/>
  }else{
    clientDisplay = client;
  }

  // Determine color based on percentage
  const getColor = (percentage) => {
    return percentage < 25 ? '#ff4d4f' : '#52c41a';
  };

  const isThereAnyTask = () => {
    if (!selectedTasks) return false

    for (let urlActivity of cardVisitedURLs){
      const isTask = isTaskURL(urlActivity.url)
      let {url, title, color} = selectedTasks.filter((obj) => isTask ? (urlActivity.url.includes(obj.url) && isTaskURL(obj.url)) : urlActivity.url.includes(obj.url))[0] || {url: null, title: null, color: null};
      if (url) return true
    }
    
    return false
  }

  const suspiciousBorders = (sfKey, timeSlot) => {
    if(sfKey!='macro') return [true, true]

    const obj = activity.suspicious_findings[sfKey]
    const firstTimeSlot = obj.consecutive_windows[0]?.time_slot;
    const lastTimeSlot = obj.consecutive_windows[obj.consecutive_windows.length - 1]?.time_slot;
    
    if (areEqualTimeSlots(timeSlot, firstTimeSlot)) return [true, false]
    if (areEqualTimeSlots(timeSlot, lastTimeSlot)) return [false, true]
    
    return [false, false]
  }

  return (
    <Badge title='Contains suspicious activity' count={Object.keys(activity.suspicious_findings).length>0 ? "!" : 0}>
    <Card className="time-window-card" style={{ minHeight: 400, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingBottom: 10 }}>
        <div ref={ref5}>
        <Row justify="start" align="middle">
          <Col span={4}>
            <Tooltip title={client}>
              {clientDisplay}
            </Tooltip>
          </Col>
          <Col span={20}>
            <p>{project_name}</p>
          </Col>
        </Row>
        </div>
        <Row justify="space-around" style={{marginTop: 10}}>
          <Col span={10} className="align-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Tooltip title="Started clocking">
              {has_start ? <span ref={ref6}><MdPlayArrow style={{ fontSize: 18, marginRight: 5, color: '#2a4dff' }} /></span> : null}
            </Tooltip>
            <p style={{ margin: 0 }}>{startTime.format("HH:mm")}</p>
          </Col>
          <Col span={10} className="align-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Tooltip title="Stopped clocking">
              {has_stop ? <span ref={ref6}><MdStop style={{ fontSize: 18, marginRight: 5, color: '#2a4dff' }} /></span> : null}
            </Tooltip>
            <p style={{ margin: 0 }}>{endTime.format("HH:mm")}</p>
          </Col>
        </Row>
        <div ref={ref7}>
        <Row>
          <Col span={24}>
            <Tooltip title="Overall Activity">
              <Progress
                size="small"
                percent={Math.round(overall_percentage)}
                strokeColor={getColor(overall_percentage)}
                format={(percent) => <p>{percent} % of {activity_tracked_time >= 60 ? `${Math.floor(activity_tracked_time/60)} minutes` : `${activity_tracked_time} seconds`} </p>}
                percentPosition={{ align: 'center', type: 'outer' }}
                strokeLinecap="round"
              />
            </Tooltip>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Tooltip title="Mouse Activity">
              <Progress
                size="small"
                percent={Math.round(mouse_percentage)}
                strokeColor={getColor(mouse_percentage)}
                strokeLinecap="round"
                format={() => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdMouse style={{ marginRight: 5, color: "#666666" }} />
                    <span>{Math.round(mouse_percentage)}%</span>
                  </div>
                )}
              />
            </Tooltip>
          </Col>
          <Col span={10} offset={4} className="activity-bar">
            <Tooltip title="Keyboard Activity">
              <Progress
                size="small"
                percent={Math.round(keyboard_percentage)}
                strokeColor={getColor(keyboard_percentage)}
                strokeLinecap="round"
                format={() => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdKeyboardAlt style={{ marginRight: 5, color: "#666666" }} />
                    <span>{Math.round(keyboard_percentage)}%</span>
                  </div>
                )}
              />
            </Tooltip>
          </Col>
        </Row>
        {
          Object.keys(activity.suspicious_findings).length > 0
          ?
          <Divider orientation='center'><p style={{fontSize: 12}}>Flags</p></Divider>
          :
          null
        }
        <Row justify='center' align='middle' gutter={[0, 5]}>
          {
            Object.keys(activity.suspicious_findings).map(sfKey => 
              <Col key={`${activity.time_slot}-${sfKey}`} span={24} style={{ textAlign: 'center' }}>
                <Tooltip title={activity.suspicious_findings[sfKey].details || activity.suspicious_findings[sfKey].explanation}>
                  <Tag ref={flagRef} color={'error'} bordered={false} style={{
                    borderLeft: suspiciousBorders(sfKey, activity.time_slot)[0] ? '2px solid red' : 'none',
                    borderRight: suspiciousBorders(sfKey, activity.time_slot)[1] ? '2px solid red' : 'none'
                    }}>{sfKey}</Tag>
                </Tooltip>
              </Col>
            )
          }
        </Row>
        </div>
        {
          isThereAnyTask()
          ?
          <Divider orientation='left'><p style={{fontSize: 12}}>URLs</p></Divider>
          :
          null
        }
        <Row justify="space-around" style={{ marginTop: 20, maxHeight: '150px', overflowY: 'auto' }}>
        <VisitedTasksTags cardVisitedURLs={cardVisitedURLs} overallTracked={activity_tracked_time}></VisitedTasksTags>
      </Row>
        {
          activity.app_details.length > 0
          ?
          <Divider orientation='left'><p style={{fontSize: 12}}>APPs</p></Divider>
          :
          null
        }
      <Row justify="space-around" style={{ marginTop: 20, maxHeight: '150px', overflowY: 'auto' }}>
        <ApplicationsTags timeSlot={activity.time_slot} cardAppDetails={activity.app_details} overallTracked={activity_tracked_time}></ApplicationsTags>
      </Row>
    </Card>
    </Badge>
  );
};

ActivityCard.propTypes = {
  activity: PropTypes.object.isRequired,
  cardVisitedURLs: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ActivityCard;